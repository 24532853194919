<template>
  <div class="pack_box">
    <div style="position:absolute;right:5%;z-index: 99999;">
      <v-chip color="transparent" label x-large @click="informationBox = true">
        <v-icon x-large left color="white" style="width:26px;">
          mdi-information-outline
        </v-icon>
      </v-chip>
    </div>
    <img :src="`${grabCoin.title_img}`" alt="babu88-coin" class="title_box img_daily_coin" />
    <span class="countdown count_down_timer">{{ end_date }}</span>
    <div class="clock_panel">
      <ul id="example">
        <p id="lucktext">{{ $t(`label.tryurluck`) }}</p>
        <li>
          <span class="days">00</span>
          <p class="" style="color:white; margin-top:0">d</p>
        </li>
        <li class="seperator">:</li>
        <li>
          <span class="hours">00</span>
          <p class="" style="color:white;margin-top:0">hr</p>
        </li>
        <li class="seperator">:</li>
        <li>
          <span class="minutes">00</span>
          <p class="" style="color:white;margin-top:0">min</p>
        </li>
        <li class="seperator">:</li>
        <li>
          <span class="seconds">00</span>
          <p class="" style="color:white;margin-top:0">sec</p>
        </li>
        <p class="grab_now">Grab now</p>
      </ul>
      <div class="circular_effect"></div>
    </div>
    <div id="demo" class="scroll-text">
      <ul>
        <li v-for="(n, k) in coinLogs" :key="k" class="d-flex justify-start align-center">
          <div class="numbering">{{ parseInt(k) + 1 }}</div>
          <span class="mxb-2 player_name">{{ GetMaskMember(n.member_code) }}</span>
          <span class="mxb-2 player_coin">{{ n.point_rewarded }} coins</span>
          <span class="mxb-2 player_date">{{ n.created_at | shortDate }}</span>
        </li>
      </ul>
    </div>
    <!--Start animation box-->
    <div class="start_box">
      <span></span>
    </div>
    <!--Red envelope box-->
    <ul class="redpack_box"></ul>
    <!--Pop-up box-->
    <v-dialog class="pop_box" max-width="500px" v-model="isWinPrize" transition="top">
      <v-card color="white" min-height="200" max-width="500px">
        <v-card-title class="primary white--text">
          {{ title }}
        </v-card-title>
        <div class="pop_con pa-5">
          <h3 v-if="prize > 0 && prize != 'miss'">Congratulations, {{ prize }} coins has been credited to your coin wallet.</h3>
          <h3 v-else-if="error != ''">{{ error }}</h3>
          <h3 v-else>Ops, sorry please try again next time</h3>
        </div>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="isWinPrize = false" ripple outlined color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog class="pop_box" max-width="500px" v-model="informationBox">
      <v-card color="white" min-height="200" max-height="450" max-width="500" class="mx-auto" style="overflow-y: auto">
        <v-card-title class="primary white--text">
          {{ $t(`label.termsAndConditions`) }}
        </v-card-title>
        <div class="pop_con pa-5">
          <ol>
            <li>{{ getCoinMessage() }}</li>
            <li v-if="!is_recurring_chance">
              {{ $t(`label.is_not_recurring`) }}
            </li>
            <li v-else>
              {{ getChanceMessage() }}
            </li>
            <li>{{ $t(`label.not_carry`) }}</li>
            <li>{{ $t(`label.first_come_first_serve`) }}</li>
            <li>{{ $t(`label.redemp_coin`) }}</li>
            <li>{{ $t(`label.used_coin`) }}</li>
            <li>{{ $t(`label.t&c_applied`) }}</li>
          </ol>
        </div>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="informationBox = false" ripple outlined color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-dialog-status
      ref="appDialog"
      :dialogShow="dialogForm.show"
      :dialogTitle="dialogForm.title"
      :dialogMessages="dialogForm.messages"
      :dialogFields="dialogForm.fields"
      :dialogButtons="dialogForm.buttons"
    ></app-dialog-status>
  </div>
</template>

<script>
import { MEMBER_CHANGE_PASSWORD } from '@/store/member.module'
import { GRAB_COIN_NOW, GRAB_COIN_SETTING, RESET_GRAB_COIN, REWARD_COIN_LOGS } from '@/store/bonus.module'
import { API } from '@/constants/constants'
import { locale, uiHelper } from '@/util'
import currencyFormat from '@/filters/currency-format'
import format from 'string-format'
import { ROUTE_NAME } from '@/constants/route.constants'
import { REWARD_CONFIGURATION_STATUS } from '@/store/reward.module'
import $ from 'jquery'
export default {
  name: 'GrabCoin',
  data: () => ({
    max_chances_per_day: 1,
    informationBox: false,
    end_date: '',
    API_URL: API.BASE_URL,
    image_two: '',
    image_one: '',
    prize: 0,
    isWinPrize: false,
    error: '',
    title: '',
    min_deposit: 0,
    is_recurring_chance: false,
    dialogForm: {
      show: false,
      title: '',
      messages: [],
      buttons: [],
      fields: []
    }
  }),
  async mounted() {
    let func = this
    /**
     * Created by Administrator on 2017/7/5.
     * 红包雨
     */
    $(document).ready(function() {
      $.when(pullData()).done(function(a1) {
        if ($('.countdown').length) {
          $.each($('.countdown'), function(key, value) {
            var time_listed = $(value).text()
            var countdown_object = $(value)

            // Set the date we're counting down to
            var t = time_listed.split(/[- :]/)

            // Apply each element to the Date function
            var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
            var countDownDate = new Date(d).getTime()
            var counterFunction = function() {
              // Get today's date and time
              var now = new Date().getTime()

              // Find the distance between now and the count down date
              var distance = countDownDate - now

              // Time calculations for days, hours, minutes and seconds
              var days = Math.floor(distance / (1000 * 60 * 60 * 24))
              var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
              var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
              var seconds = Math.floor((distance % (1000 * 60)) / 1000)

              // Display the result in the element with id="demo"
              countdown_object.text(days + ' : ' + hours + ' : ' + minutes + ' : ' + seconds + '')
              $('.days').text(days)
              $('.hours').text(hours)
              $('.minutes').text(minutes)
              $('.seconds').text(seconds)

              // If the count down is finished, write some text
              if (distance < 0) {
                clearInterval(x)
                countdown_object.text('EXPIRED')
              }
              return counterFunction
            }
            // Update the count down every 1 second
            var x = setInterval(counterFunction(), 1000)
          })
        }
      })
      // 页面加载完毕之后设置的初始样式
      initPage()

      //开始动画
      startAnimation()

      //添加红包
      setTimeout(add, 3000)
    })

    function pullData() {
      let currency = uiHelper.getCurrency()
      return $.get(func.API_URL + '/reward/grab-coin?currency=' + currency, function(data) {
        func.image_one = data.data.icon_one
        func.image_two = data.data.icon_two
        func.end_date = data.data.end_date
        func.title = data.data.grab_name
        func.min_deposit = data.data.min_deposit
        func.max_chances_per_day = data.data.max_chances_per_day
        func.is_recurring_chance = data.data.is_recurring_chance
      })
    }

    function initPage() {
      $('.start_box').css('height', $('html').height())
      $('.redpack_box').css('height', $('html').height())
    }

    function startAnimation() {
      var num = 4
      var startA = function() {
        num--
        if (num > 0) {
          $('.start_box span').html(num)
        } else {
          $('.start_box').remove()
        }
        setTimeout(startA, 1000)
      }
      startA()
    }

    //循环随机数的方法
    function randomNum(startNum, endNum) {
      return parseInt(Math.random() * (endNum + 1 - startNum) + startNum)
    }

    var nums = 0
    var wid = parseInt($('.redpack_box').width()) - 60

    function add() {
      var ranImg = randomNum(1, 2) //此随机数用来切换图片用。
      var ranWidth = randomNum(50, 150) //此随机数用来设置红包的宽度。
      var ranLeft = randomNum(0, wid) //用来设置红包的left值。
      var ranRotate = randomNum(-45, 45) //设置红包的rotate值。

      nums++

      let imageName = func.image_one
      if (ranImg == 2) {
        imageName = func.image_two
      }
      var redpackHTML = "<li style='list-style-type: none' class='li" + nums + "'>" + "<a href='javascript: void(0);'>" + "<img src='" + imageName + "'></a></li>"

      $('.redpack_box').append(redpackHTML)
      $('.li' + nums).css('left', ranLeft)
      $('.li' + nums + ' img').css({
        width: ranWidth + 'px',
        transform: 'rotate(' + ranRotate + 'deg)',
        '-ms-transform': 'rotate(' + ranRotate + 'deg)' /* Internet Explorer */,
        '-moz-transform': 'rotate(' + ranRotate + 'deg)' /* Firefox */,
        '-webkit-transform': 'rotate(' + ranRotate + 'deg)' /* Safari 和 Chrome */,
        '-o-transform': 'rotate(' + ranRotate + 'deg)' /* Opera */
      })

      $('.li' + nums).animate({ top: $(document).height() + 20 }, 5000, function() {
        //删除掉落的红包
        $(this).remove()
      })

      $('.li' + nums).click(function() {
        if (!func.isLoggedIn) {
          func.$parent.goToLoginPage()
          return
        } else {
          func.randomPrize()
        }
        // $(".pop_box").css("display", "block");
      })

      setTimeout(add, 700)
    }

    $('.pop_con a').click(function() {
      $('.pop_box').css('display', 'none')
    })

    $('#demo').scrollbox()

    // var now = new Date();
    // let expired = func.end_date;
    // let arrDate = expired.split(' ');
    // let digit = arrDate[0].split('-');
    //
    // var day = digit[2];
    // var month = digit[1];
    // var year = digit[0];
    //
    // var nextyear = month + '/' + day + '/' + year + ' ' + arrDate[1];

    // $('#example').countdown({
    //   date: nextyear, // TODO Date format: 07/27/2017 17:00:00
    //   offset: +8, // TODO Your Timezone Offset
    //   day: 'Day',
    //   days: 'Days',
    //   hideOnComplete: true
    // }, function (container) {
    //   alert('Done!');
    // });
  },
  created() {
    this.metaTag()
    this.getGrabCoinSetting()
    this.getCoinLogs()
    this.getInfoMessage()
    this.getConfigurationStatus()
  },

  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    getConfigurationStatus() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${REWARD_CONFIGURATION_STATUS}`, { filter })
    },
    getInfoMessage() {
      let d = this.configuration.coin_grab.enabled
      if (d == false) {
        let dialog = this.$parent.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.info`)
        dialog.message.push(locale.getMessage(`label.status_info`))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: () => {
            this.navigateToHomePage()
          }
        })

        dialog.dialogXButton = null
        this.$parent.openPageDialog(dialog)
      }
      this.getConfigurationStatus()
    },
    getChanceMessage() {
      return locale.getMessage('label.is_recurring', [currencyFormat(this.min_deposit), this.max_chances_per_day])
    },
    getCoinMessage() {
      return locale.getMessage('label.deposit_requirement', [currencyFormat(this.min_deposit)])
    },
    randomPrize() {
      this.$store.dispatch(`${GRAB_COIN_NOW}`)
    },
    GetMaskMember(membercode) {
      let len = membercode.length
      let lenMinus = len - 3
      var last = membercode.substr(0, 3) + '***' + membercode.substr(6, len)
      return last
    },
    getCoinLogs() {
      let filter = {
        currency: 'BDT'
      }
      this.$store.dispatch(`${REWARD_COIN_LOGS}`, { filter })
    },
    getGrabCoinSetting() {
      let filter = {
        currency: 'BDT'
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, { filter })
    },
    showRequirement() {
      this.informationBox = true
    },
    navigateToHomePage() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
      this.$parent.closePageDialog()
    }
  },
  computed: {
    configuration() {
      let d = this.$store.state.reward.configurationStatus
      return d
    },
    grabCoinResult() {
      return this.$store.state.bonus.grabCoin.complete
    },
    coinLogs() {
      return this.$store.state.bonus.rewardCoinsLog
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      this.end_date = d.end_date
      return d
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    grabCoinResult() {
      if (this.$store.state.bonus.grabCoin.complete) {
        this.prize = this.$store.state.bonus.grabCoin.data
        this.error = !this.$store.state.bonus.grabCoin.success ? this.$store.state.bonus.grabCoin.error : ''
        this.isWinPrize = true
        this.getCoinLogs()
        this.$store.dispatch(`${RESET_GRAB_COIN}`)
      }
    },
    coinLogs() {
      return this.$store.state.bonus.rewardCoinsLog
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      this.end_date = d.end_date
      return d
    },
    configuration() {
      let d = this.$store.state.reward.configurationStatus
      return d
    }
  }
}
</script>

<style>
body,
html {
  font-family: 'Monserrat', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#lucktext {
  font-size: 18px;
}

.count_down_timer {
  opacity: 0;
  position: absolute;
  bottom: 0;
}

.img_daily_coin {
  width: 250px;
  height: auto;
}

.home-announcment-content {
  height: 0 !important;
}

@font-face {
  font-family: 'Monserrat';
  src: url('/static/image/GrabCoins/fonts/Monserrat.ttf') format('ttf');
}

.mxb-2 {
  margin: 5px 2px;
  font-weight: bold;
}

#demo {
  padding: 10px;
  width: 95%;
  background-color: #fd0 !important;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #0062e1;
  margin: auto;
  right: 0;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.scroll-text {
  width: 400px;
  height: 10.5em;
  overflow: hidden;
}

.scroll-text ul {
  width: 400px;
  height: 300px;
  overflow: hidden;
  padding-top: 10px;
  margin: 0 auto;
  padding-left: 7px;
}

.scroll-text ul li {
  height: 2.5em;
  list-style-type: none;
}

.scroll-text ul li span {
  margin-left: 8px;
  margin-right: 8px;
}

.numbering {
  background-color: white;
  color: #0062e1;
  border-radius: 50%;
  font-weight: bold;
  font-size: 10px;
  padding: 3px 5px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
}

.clock_panel {
  width: 400px;
  height: 400px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.title_box {
  margin: auto;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
}

.pack_box {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #fab126, #ff7c4e) no-repeat top left;
  background-size: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

/*
  Start animation box
*/
.start_box {
  width: 100%;
  position: absolute;
  background: #ccc;
  opacity: 0.5;
}

.start_box span {
  display: inline-block;
  width: 100px;
  height: 100px;
  color: #000;
  font-weight: bold;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 100px;
  font-size: 1000%;
}

/*
     Red envelope box
*/
.redpack_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.redpack_box li {
  position: absolute;
  animation: all 3s linear;
  top: -100px;
}

.redpack_box li a {
  display: block;
}

/*
     Pop-up box
*/
.pop_box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999;
}

.pop_box .pop_con {
  width: 70%;
  height: 160px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.pop_box .pop_con img {
  width: 70%;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: auto;
  vertical-align: top;
}

.pop_box .pop_con h3 {
  color: red;
  z-index: 999;
  position: absolute;
  width: 100%;
  text-align: center;
  font-weight: bold;
  top: 80px;
  font-size: 120%;
}

.pop_box .pop_con a {
  width: 36%;
  height: 38px;
  position: absolute;
  top: 120px;
  z-index: 999;
  text-decoration: none;
  color: #fff;
  background: #177ad8;
  text-align: center;
  line-height: 38px;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #177ad8;
  left: 0;
  right: 18px;
  bottom: 0;
  margin: auto;
}

.redpack_box {
  padding-left: 0 !important;
}

ul#example {
  background-image: url('/static/image/GrabCoins/icon/Circle_Bg.png');
  background-size: cover;
  border-radius: 50%;
  height: 400px;
  width: 400px;
  opacity: 0.8;
  padding-top: 100px !important;
  list-style: none;
  margin: 0px 0;
  padding: 0;
  display: block;
  text-align: center;
}

ul#example li {
  display: inline-block;
}

ul#example li span {
  font-family: 'Monserrat';
  font-weight: bold;
  font-size: 36px;
  line-height: 80px;
}

ul#example li.seperator {
  font-size: 36px;
  line-height: 70px;
  vertical-align: top;
  margin-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.grab_now {
  border: 2px solid #ffdd02;
  background-color: #ffdd02;
  width: 166px;
  margin: 0 auto;
  padding: 5px 0px;
  font-weight: bold;
  font-size: 21px;
  text-transform: uppercase;
  border-radius: 50px;
  color: purple;
  webkit-transform: skew(-4deg);
  transform: skew(-4deg);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
}

ul#example li p {
  color: #a7abb1;
  font-size: 25px;
}

.circular_effect {
  position: absolute;
  top: 0;
  width: 48.8439306358%;
  height: 48.8439306358%;
  border-color: #fcebff;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-radius: 250px 0 0 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-shadow: -1px -1px 1px rgb(153 153 204 / 50%), 1px 1px 1px rgb(153 153 204 / 50%) inset;
  box-shadow: -1px -1px 1px rgb(153 153 204 / 50%), inset 1px 1px 1px rgb(153 153 204 / 50%);
  position: absolute;
  top: -3px;
  left: -3px;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation: secondHandRotate 60s linear infinite;
  animation: secondHandRotate 60s linear infinite;
}

.circular_effect:before {
  box-shadow: -2px 0 0 #fcebff, 0 0 1px 1px rgb(153 153 204 / 50%);
  top: -5px;
  right: -2.5px;
}

.circular_effect:after {
  -webkit-box-shadow: 0 -2px 0 #fcebff, 0 0 1px 1px rgb(153 153 204 / 50%);
  box-shadow: 0 -2px 0 #fcebff, 0 0 1px 1px rgb(153 153 204 / 50%);
  left: -5px;
  bottom: -2.5px;
}

@-webkit-keyframes secondHandRotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes secondHandRotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 599px) {
  .img_daily_coin {
    width: 200px;
  }

  #demo {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }

  .mxb-2 {
    margin: 5px 2px;
    font-weight: bold;
    font-size: 16px;
  }

  .clock_panel {
    width: 300px;
    height: 300px;
  }

  ul#example {
    height: 300px;
    width: 300px;
    padding-top: 40px !important;
  }

  #lucktext {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .scroll-text {
    width: 400px;
    height: 8.5em;
    overflow: hidden;
  }

  .pack_box {
    background-size: cover;
  }
}
</style>
